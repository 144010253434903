export default {
  modList:[
    {
      title: 'FM WhatsApp',
      dlpg_title: 'Fouad WhatsApp',
      img: require('@/assets/blog6-logo-4.webp'),
      name: 'fm',
    },
    {
      title: 'GBWhatsApp iOS',
      img: require('@/assets/ios.webp'),
      name: 'ios',
    },
    {
      title: 'YO WhatsApp',
      img: require('@/assets/blog6-logo-7.webp'),
      name: 'yo',
    },
    {
      title: 'WhatsApp Plus',
      img: require('@/assets/blog6-logo-3.webp'),
      name: 'plus',
    },
    {
      title: 'OB WhatsApp',
      img: require('@/assets/blog6-logo-6.webp'),
      name: 'ob',
    },
    {
      title: 'GB WhatsApp Pro',
      img: require('@/assets/blog6-logo-2.webp'),
      name: 'pro',
    }
  ]
}